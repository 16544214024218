// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, Grid, styled } from '@mui/material'
// src
import SectionText, { SectionTextProps } from '@molecules/text/SectionText'
import { SectionTextVariants } from 'constants/section-text-variants'
import { createImageSectionTextDefaults } from 'helpers/createImageSectionTextDefaults'

interface Props extends SectionTextVariants {
  image: IGatsbyImageData
  secondaryImage?: IGatsbyImageData
  keyId: string
  subtitle: string
  mainHeading: string
  text: string
  button?: Queries.DatoCmsButton
  imageStamp?: boolean
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: theme.breakpoints.values.md,
  },
}))

const Image = styled(GatsbyImage)(({ theme }) => ({
  ['&.primary-image']: {
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(6),
    boxShadow: `-80px -40px 0 20px ${theme.palette.primary.light}`,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(18),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(20),
      marginBottom: theme.spacing(10),
      boxShadow: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(18),
      marginBottom: theme.spacing(8),
      boxShadow: `-920px 0 0 1000px ${theme.palette.primary.light}`,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(20),
      marginBottom: theme.spacing(10),
      boxShadow: `-900px 0 0 1000px ${theme.palette.primary.light}`,
    },
  },
  ['&.secondary-image']: {
    position: 'absolute !important',
    bottom: 0,
    right: 0,
    width: '220px',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
    },
    [theme.breakpoints.up('md')]: {
      width: '360px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '260px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '300px',
    },
  },
}))

const ContentSectionTextImage = ({
  image,
  secondaryImage,
  sectionTextVariant,
  keyId,
  subtitle,
  mainHeading,
  button,
  text,
}: Props) => {
  const sectionTextProps: SectionTextProps = createImageSectionTextDefaults({
    sectionTextVariant,
    keyId,
    mainHeading,
    subtitle,
    text,
    button,
  })

  return (
    <Box
      pt={{ lg: 9, xl: 12 }}
      pb={{ lg: 8, xl: 11 }}
      sx={{ backgroundColor: 'white' }}
    >
      <Box
        py={{ xs: 6, xl: 9 }}
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
      >
        <StyledContainer maxWidth="xl">
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                order: 2,
                mt: {
                  xs: 3,
                  lg: 0,
                },
              }}
            >
              <div>
                <SectionText {...sectionTextProps} />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                order: 1,
              }}
            >
              <Box
                position="relative"
                mr={{ lg: 4, xl: 6 }}
                ml={{ xs: -2.5, sm: 0 }}
              >
                <Image image={image} alt="" className={`primary-image`} />
                <Image
                  image={secondaryImage}
                  alt=""
                  className={`secondary-image`}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
      </Box>
    </Box>
  )
}

export default memo(ContentSectionTextImage)
