import { SectionTextProps } from '@molecules/text/SectionText'
import { SectionTextVariants } from 'constants/section-text-variants'

interface CreateImageSectionTextDefaultProps extends SectionTextVariants {
  keyId: string
  mainHeading: string
  subtitle: string
  text?: string
  button?: Queries.DatoCmsButton
}

export const createImageSectionTextDefaults = ({
  sectionTextVariant,
  keyId,
  mainHeading,
  subtitle,
  text,
  button,
}: CreateImageSectionTextDefaultProps): SectionTextProps => {
  return {
    sectionTextVariant: sectionTextVariant,
    mainHeadingProps: {
      typographyProps: {
        variant: 'h2',
      },
      lineBreaksProps: {
        keyId: keyId,
        text: mainHeading,

        breakpoint: 'xs',
      },
    },
    subtitleProps: {
      color: 'primary.main',
      text: subtitle,
      borderType: 'left',
      typographyProps: {
        component: 'h2',
      },
    },
    text: text,
    button: button,
    buttonLinkProps: {
      colorVariant: 'primary-text',
    },
  }
}
